import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import i18n from "@/libs/i18n";
import * as VueGoogleMaps from "vue2-google-maps";

import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;
//register laravel vue pagination global
Vue.component("pagination", require("laravel-vue-pagination"));
Vue.filter("formatDate", function (value) {
  var d = new Date(value),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
});
Vue.filter("globalNumberDisplay", function (value) {
  if (!value) return 0.0;
  if (value >= 0) {
    return parseFloat(value).toFixed(2);
  } else {
    return `(${Math.abs(value).toFixed(2)})`;
  }
});

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyC64MhP5bNrmPeMqN8EpBWaybGqa5dLlXA",
  },
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
