<template>
  <div v-show="isLoading">
    <div id="loading-bg">
      <div class="loading-logo">
        <img src="/logo.png" alt="Logo" style="width: 150px" />
      </div>
      <br />
      <br />
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
};
</script>
