import Vue from "vue";
import VueRouter from "vue-router";
import { isUserLoggedIn, getUserData } from "@/auth/utils";
import mainroutes from "./routes";

Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: mainroutes,
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();
  if (
    !isLoggedIn &&
    to.name != "user-login" &&
    to.name != "auth-forgot-password" &&
    to.name != "auth-reset-password"
  )
    return next({ name: "user-login" });

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    next("/");
  }

  return next();
});

export default router;
