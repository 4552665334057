export default [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home.vue"),
    meta: {
      pageTitle: "Home",
      breadcrumb: [
        {
          text: "Home",
          active: true,
        },
      ],
    },
  },
  {
    path: "/second-page",
    name: "second-page",
    component: () => import("@/views/SecondPage.vue"),
    meta: {
      pageTitle: "Second Page",
      breadcrumb: [
        {
          text: "Second Page",
          active: true,
        },
      ],
    },
  },
  {
    path: "/login",
    name: "user-login",
    component: () => import("@/views/Login.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/logout",
    name: "client.logout",
    component: {
      beforeRouteEnter(to, from, next) {
        localStorage.removeItem("token");

        localStorage.removeItem("userData");

        window.location.href = "/login";
      },
    },
  },
  // client.logout

  {
    path: "/transport/open-waybills",
    name: "transport.open-waybills",
    component: () => import("@/views/transport/OpenWaybills.vue"),
    meta: {
      pageTitle: "Open Waybills",
      breadcrumb: [
        {
          text: "Transport",
        },
        {
          text: "Open Waybills",
          active: true,
        },
      ],
    },
  },

  {
    path: "/transport/arrived-waybills",
    name: "transport.arrived-waybills",
    component: () => import("@/views/transport/ArrivedWaybills.vue"),
    meta: {
      pageTitle: "Arrived Waybills",
      breadcrumb: [
        {
          text: "Transport",
        },
        {
          text: "Arrived Waybills",
          active: true,
        },
      ],
    },
  },

  {
    path: "/transport/waybills/:id/view",
    name: "transport.waybills.view",
    component: () => import("@/views/transport/NotificationView.vue"),
    meta: {
      pageTitle: "Waybills",
      breadcrumb: [
        {
          text: "Transport",
        },
        {
          text: "View",
          active: true,
        },
      ],
    },
  },
  // transport.waybills.tracking

  {
    path: "/transport/waybills/:id/tracking",
    name: "transport.waybills.tracking",
    component: () => import("@/views/transport/Tracking.vue"),
    meta: {
      pageTitle: "Waybills",
      breadcrumb: [
        {
          text: "Transport",
        },
        {
          text: "Tracking",
          active: true,
        },
      ],
    },
  },

  {
    path: "/clearance/open-transactions",
    name: "clearance.open-transactions",
    component: () => import("@/views/clearance/OpenTransactions.vue"),
    meta: {
      pageTitle: "Open Transactions",
      breadcrumb: [
        {
          text: "Clearance",
        },
        {
          text: "Open Transactions",
          active: true,
        },
      ],
    },
  },
  {
    path: "/clearance/closed-transactions",
    name: "clearance.closed-transactions",
    component: () => import("@/views/clearance/ClosedTransactions.vue"),
    meta: {
      pageTitle: "Closed Transactions",
      breadcrumb: [
        {
          text: "Clearance",
        },
        {
          text: "Closed Transactions",
          active: true,
        },
      ],
    },
  },
  // clearance.transactions.view

  {
    path: "/clearance/transactions/:id/view",
    name: "clearance.transactions.view",
    component: () => import("@/views/clearance/TransactionDetails.vue"),
    meta: {
      pageTitle: "Closed Transactions",
      breadcrumb: [
        {
          text: "Clearance",
        },
        {
          text: "Transaction",
          active: true,
        },
      ],
    },
  },
  {
    path: "/client/invoices",
    name: "client.invoices",
    component: () => import("@/views/client/Invoices.vue"),
    meta: {
      pageTitle: "Invoices",
      breadcrumb: [
        {
          text: "Client",
        },
        {
          text: "Invoices",
          active: true,
        },
      ],
    },
  },
  // client.invoices.view
  {
    path: "/client/invoices/:id/view",
    name: "client.invoices.view",
    component: () => import("@/views/client/InvoiceDetails.vue"),
    meta: {
      pageTitle: "Invoices",
      breadcrumb: [
        {
          text: "Client",
        },
        {
          text: "Invoices",
        },
        {
          text: "Invoice Details",
        },
      ],
    },
  },

  {
    path: "/client/returns",
    name: "client.returns",
    component: () => import("@/views/client/Returns.vue"),
    meta: {
      pageTitle: "Returns",
      breadcrumb: [
        {
          text: "Client",
        },
        {
          text: "Returns",
          active: true,
        },
      ],
    },
  },

  {
    path: "/client/account-statement",
    name: "client.account-statement",
    component: () => import("@/views/client/Accountstatement.vue"),
    meta: {
      pageTitle: "Account statement",
      breadcrumb: [
        {
          text: "Client",
        },
        {
          text: "Account statement",
          active: true,
        },
      ],
    },
  },
  // 404
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/error/Error404.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "*",
    redirect: "error-404",
  },
];

/*

  {
    title: "Returns",
    route: "second-page",
    icon: "FileIcon",
  },

  {
    title: "Account statement",
    route: "second-page",
    icon: "FileIcon",
  },
];

*/
